import React from 'react'
import Pagegeneric from './Pagegeneric'

const Hoddies = () => {
  return (
    <>
      <Pagegeneric/>
    </>
  )
}

export default Hoddies
