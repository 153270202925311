import React from 'react'
import HomeSlider from '../sliders/HomeSlider'
import Catogries from '../homecompo/Catogries'

const Home = () => {
  return (
    <div>
     <HomeSlider/>
     <Catogries/>
    </div>
  )
}

export default Home
