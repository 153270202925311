import React, { useEffect, useState } from 'react'
import Navbar from './items/Navbar'
import Routing from './routes/Routing'
import Footer from './items/Footer'
import HashLoader from "react-spinners/HashLoader"
import logo2 from '../src/logos/cloth-logo.png'
const App = () => {

  const[loading,setLoading]=useState(true)
  useEffect(()=>{
      setTimeout(()=>{
          setLoading(false)
      },2000)
  },[])

  return (
    <div className='flex-col gap-2'>
      {loading?
      <div className=' bg-gray-800 w-full h-[100vh] grid place-content-center' >
            <img src={logo2} alt='not found' className='h-10 drop-shadow-[-2px_2px_2px_black] mt-3 ml-10 animate-bounce'/>
      </div>:
      <>
      <Navbar/>
      <Routing/>
      <Footer/>
      </>}
    </div>
  )
}

export default App
