import React from 'react'

const Catogries = () => {
  return (
    <div className='w-full box-border'>

      <h1 className='grid place-content-center text-3xl py-5 font-semibold'>All Catogries</h1>
     
    </div>
  )
}

export default Catogries
